import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Img from 'gatsby-image';

import { showMenu, hideMenu } from '../store/ui/actions';
import { noop, openCashier } from '../util';
import { useIsPlayer } from '../hooks';
import PlayerBalanceStatus from './playerBalanceStatus';
import ApgSeal from './apgSeal';
import Pap from './pap';
import Logo from './logo';
import Hamburger from './hamburger';
import En from './icons/en';
import Es from './icons/es';
import De from './icons/de';
import Pt from './icons/pt';
import Menu from './menu';
import Carousel from './carousel';
import CookiesTooltip from './cookiesTooltip';
import './pageLayout.css';


const langIconClass = 'inline-block w-5 2xl:w-6';
const langs = [
  {
    to: {
      en: '/'
    },
    language: 'en',
    element: <span className={langIconClass}><En /></span>
  },
  {
    to: {
      en: '/'
    },
    language: 'es',
    element: <span className={langIconClass}><Es /></span>
  },
  {
    to: {
      en: '/'
    },
    language: 'de',
    element: <span className={langIconClass}><De /></span>
  },
  {
    to: {
      en: '/'
    },
    language: 'pt',
    element: <span className={langIconClass}><Pt /></span>
  }
];

function menuStateSelector(s) {
  return s.ui.showMenu;
}

function PageLayout({ children, hideFoooter }) {
  const isPlayer = useIsPlayer();
  const { t, i18n: { language } } = useTranslation();
  const {
    site: { siteMetadata: { shortTitle, menus: { main, footer } } },
    legalAgeLogo: { publicURL: over18Img }, gameProviderLogos, paymentProviderLogos,
    askGamblers, lcb, bestBtc, playcasino, mrgamble
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            shortTitle
            menus {
              main {
                key
                to {
                  en
                  de
                  es
                  pt
                }
                partiallyActive
              }
              footer {
                key
                to {
                  en
                  de
                  es
                  pt
                }
              }
              mobile {
                key
                to {
                  en
                  de
                  es
                  pt
                }
              }
            }
          }
        }
        legalAgeLogo: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "18-over.svg"}) {
          publicURL
        }
        askGamblers: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "askgamblers.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        lcb: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "lcb.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bestBtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "best-btc.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        playcasino: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "777-playcasino.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mrgamble: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "MrGamble-Seal.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gameProviderLogos: allFile(filter: {sourceInstanceName: {eq: "images"}, relativePath: {glob: "game-providers/*"}}) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        paymentProviderLogos: allFile(filter: {sourceInstanceName: {eq: "images"}, relativePath: {glob: "payment-providers/*"}}) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );
  const showMenuState = useSelector(menuStateSelector);
  const dispatch = useDispatch();
  const doMenuHide = () => dispatch(hideMenu());

  return (
    <>
      <div className="site-width-boundary mx-auto">
        <div className="w-full flex flex-nowrap">
          <div className={`sidebar pb-5${showMenuState ? ' show' : ''}`}>
            <div className="mobile:flex mobile:justify-between mobile:items-top">
              <Link
                to="/"
                className="block xl:w-3/5 xl:mb-6 xl:mx-auto text-logo mobile:text-primary micro:w-32 micro:pl-4 w-52 pl-6"
                onClick={doMenuHide}
              >
                <Logo className="hidden mobile:block" mobile />
                <Logo className="block mobile:hidden" />
              </Link>
              <span
                className="text-6xl hidden mobile:block pb-1 micro:px-2 px-3"
                aria-label={t('closeMenu')}
                onClick={doMenuHide}
                onKeyUp={noop}
                role="button"
                tabIndex="0"
              >
                &times;
              </span>
            </div>
            <Menu
              items={langs}
              language={language}
              onItemClick={doMenuHide}
              className="list-none w-1/2 my-0 mx-auto p-0 pl-4 flex flex-wrap justify-evenly"
              itemClassName="py-0.5 px-1 sm:px-2 sm:py-1 text-sm sm:text-base"
            />
            {!isPlayer && <div key="anon" className="flex flex-row justify-evenly menu login lg:ml-12 mb-5 mt-3 flex-wrap">
              <Link
                to="/login/"
                className="uppercase text-2xl 2xl:text-3xl p-2 m-1 font-bold"
                activeClassName="active"
                onClick={doMenuHide}
              >
                {t('auth:loginButton')}
              </Link>
              <Link
                to="/sign-up/"
                className="uppercase text-2xl 2xl:text-3xl p-2 m-1 font-bold"
                activeClassName="active"
                onClick={doMenuHide}
              >
                {t('auth:signUp.button')}
              </Link>
            </div>}
            {isPlayer && <div key="user" className="flex flex-row flex-wrap justify-evenly menu login lg:ml-12 mb-6">
              <Link
                to="/profile/"
                className="uppercase text-2xl 2xl:text-3xl p-2 m-1 font-bold"
                activeClassName="active"
                onClick={doMenuHide}
              >
                {t('auth:profileButton')}
              </Link>
              <span
                className="uppercase text-2xl 2xl:text-3xl p-2 m-1 font-bold cursor-pointer"
                role="button"
                tabIndex="0"
                onClick={() => {
                  doMenuHide();
                  openCashier(language);
                }}
                onKeyUp={noop}
              >
                {t('auth:cashierButton')}
              </span>
              <Link
                to="/logout/"
                className="uppercase text-2xl 2xl:text-3xl p-2 m-1 font-bold"
                activeClassName="active"
                onClick={doMenuHide}
              >
                {t('auth:logoutButton')}
              </Link>
            </div>}
            {isPlayer && <div className="pl-6 pr-4 mb-5"><PlayerBalanceStatus /></div>}
            <Menu
              items={main}
              language={language}
              onItemClick={doMenuHide}
              className="menu main list-none mx-0 ml-24 2xl:ml-28 mr-4 2xl:mr-8 mb-12 2xl:mb-16 mobile:mx-auto"
              itemClassName="uppercase text-xl 2xl:text-2xl my-3.5 font-bold mobile:text-center"
            />
            <Menu
              items={footer}
              language={language}
              onItemClick={doMenuHide}
              className="menu list-none mx-0 ml-24 2xl:ml-28 mr-4 2xl:mr-8 mb-10 2xl:mb-14 mobile:mx-auto"
              itemClassName="text-xl 2xl:text-2xl my-3.5 font-bold mobile:text-center"
            />
          </div>
          <div className="content flex-grow pb-5">
            <div className="justify-between items-center hidden mobile:flex">
              <Link className="m-4 sm:mx-10 w-full" style={{ maxWidth: '6rem' }} to="/">
                <Logo mobile />
              </Link>
              <span
                className="m-3 md:m-6 p-3 md:p-4"
                aria-label={t('openMenu')}
                onClick={() => dispatch(showMenu())}
                onKeyUp={noop}
                role="button"
                tabindex="0"
              >
                <Hamburger />
              </span>
            </div>
            {children}
          </div>
        </div>
        {!hideFoooter && (
          <div className="w-full py-2 bg-secondary-bg relative z-10">
            <div className="mx-auto px-4 sm:px-8 w-full max-w-screen-xl">
              <p className="text-center uppercase mt-4 xl:mt-6 h3 accented">Game providers</p>
              <Carousel
                itemsPerSlide={[1, 1, 3, 4, 5]}
                hideDots
                arrows
              >
                {gameProviderLogos.edges.map(({ node: { childImageSharp } }, i) => (
                  <div key={i} className="w-full"><Img fluid={childImageSharp.fluid} alt="" /></div>
                ))}
              </Carousel>
              <p className="text-center uppercase mt-6 xl:mt-8 h3 accented">Payment providers</p>
              <Carousel
                itemsPerSlide={[1, 1, 3, 4, 5]}
                hideDots
                arrows
              >
                {paymentProviderLogos.edges.map(({ node: { childImageSharp } }, i) => (
                  <div key={i} className="w-full"><Img fluid={childImageSharp.fluid} alt="" /></div>
                ))}
              </Carousel>
              <Menu
                items={footer}
                language={language}
                className="list-none w-5/6 my-0 mx-auto p-0 flex flex-wrap justify-evenly pt-2 sm:pt-4 pb-5 sm:pb-7 mt-6 xl:mt-8"
                itemClassName="py-0.5 px-1.5 sm:px-2.5 sm:py-1 text-base sm:text-lg"
                linkClassName="hover:text-accent1"
              />
              <div className="mb-10 flex justify-center items-center flex-col lg:flex-row lg:flex-wrap">
                <a className="m-6 md:m-10 w-full max-w-xs" href="https://www.bestbitcoincasino.com/review/777bay-casino/" target="_blank" rel="noopener noreferrer">
                  <Img
                    className="rounded-md"
                    fluid={bestBtc.childImageSharp.fluid}
                    alt="Best Bitcoin Casino logo"
                  />
                </a>
                <a className="m-6 md:m-10 w-full max-w-xs" href="https://www.askgamblers.com/online-casinos/reviews/777bay-casino" target="_blank" rel="noopener noreferrer">
                  <Img
                    className="rounded-md"
                    fluid={askGamblers.childImageSharp.fluid}
                    alt="AskGamblers logo"
                  />
                </a>
                <a className="m-6 md:m-10 w-full max-w-xs" href="https://lcb.org/casinos/777bay-casino" target="_blank" rel="noopener noreferrer">
                  <Img
                    className="rounded-md"
                    fluid={lcb.childImageSharp.fluid}
                    alt="LCB Casinos logo"
                  />
                </a>
                <a className="m-6 md:m-10 w-full max-w-xs" href="https://www.playcasino.com/de" target="_blank" rel="noopener noreferrer">
                  <Img
                    className="rounded-md"
                    fluid={playcasino.childImageSharp.fluid}
                    alt=""
                  />
                </a>
                <a className="m-6 md:m-10 w-full" style={{ maxWidth: '7rem' }} href="https://mr-gamble.com/en/online-casino/777bay-casino/" target="_blank" rel="noopener noreferrer">
                  <Img
                    className="rounded-md"
                    fluid={mrgamble.childImageSharp.fluid}
                    alt="777 Bay Casino Review at Mr Gamble"
                  />
                </a>
              </div>
              <ApgSeal />
              <div style={{ width: '150px', height: '85px' }} className="mb-10 mx-auto">
                <a href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbGxZTWxSc1FtbDNLMnhNZGxKS2VqZDZaM1JNY1VFOVBTSXNJblpoYkhWbElqb2lUR1ZZT1VKWWFpdGxhbEpHVlVSMVZqZ3hiMlo0WnowOUlpd2liV0ZqSWpvaVlqSmhObVJqTURNMU1HSm1ZVFV5TXpnelpqQTJNV1JrTlRRM1lqUTRaalU1WW1Sa05tWmhZek5tWW1NMVl6RTRabVV6TW1RMk9URTJPRFU1WldGak5pSXNJblJoWnlJNklpSjk=">
                  <img src="https://seal.cgcb.info/8b089e55-4795-425a-6bf6-fdb75eba6900" />
                </a>
              </div>
              <p className="text-center text-xs sm:text-sm">{t('footer.mirageCorp', { siteTitle: shortTitle })}</p>
              <p className="text-center text-xs sm:text-sm"><img src={over18Img} className="w-8 inline-block mr-2" alt="" />{t('footer.18Only', { siteTitle: shortTitle })}</p>
              <p className="text-center text-xs sm:text-sm">© 2024 {shortTitle}. {t('footer.allRightsReserved')}</p>
              <Pap />
            </div>
            <div className="absolute top-full h-16 bg-secondary-bg w-full xl:hidden"></div>
          </div>
        )}
      </div>
      <CookiesTooltip />
    </>
  );
}

export default PageLayout;
