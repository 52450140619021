import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';

import { formatCurrency } from '../util';
import { useCurrencyCode, usePlayerBalance, useActiveBonus } from '../hooks';
import { fetchPlayerBalance } from '../store/playerBalance/actions';


const commonTextClasses = 'font-bold text-center uppercase text-lg 2xl:text-xl';

function PlayerBalanceStatus() {
  const { t } = useTranslation();
  const { loading, balance, bonus } = usePlayerBalance();
  const activeBonus = useActiveBonus();
  const currencyCode = useCurrencyCode();
  const dispatch = useDispatch();

  if (typeof balance !== 'number' && loading) {
    return (
      <p className={commonTextClasses}>{t('auth:loadingBalanceText')}</p>
    );
  }

  let activeBonusAmount = t('auth:noActiveBonusText');
  if (bonus) {
    activeBonusAmount = formatCurrency(bonus, currencyCode);
  }

  let activeBonusClearedPercentage = undefined;
  if (activeBonus) {
    const { pointsNeeded, contributionTotal } = activeBonus;
    if (typeof contributionTotal === 'number' && typeof pointsNeeded === 'number' && pointsNeeded > 0) {
      activeBonusClearedPercentage = contributionTotal / pointsNeeded * 100;

      if (activeBonusClearedPercentage < 0) {
        activeBonusClearedPercentage = 0;
      } else if (activeBonusClearedPercentage > 100) {
        activeBonusClearedPercentage = 100;
      } else {
        activeBonusClearedPercentage = activeBonusClearedPercentage.toFixed(2);
      }

      activeBonusClearedPercentage += '%';
    }
  }

  return (
    <>
      <p className={commonTextClasses}>
        {t('auth:balanceAmountText', { balance: formatCurrency(balance, currencyCode) })}
      </p>
      <p className="text-center">
        <button className="button primary" disabled={loading} onClick={() => dispatch(fetchPlayerBalance())}>{t('auth:refreshBalance')}</button>
      </p>
      <p className={commonTextClasses}>
        {t('auth:activeBonusAmountText', { bonus: activeBonusAmount })}
      </p>
      {activeBonusClearedPercentage &&
        <div className="border border-accent2 rounded-xl p-1 w-full mx-auto relative overflow-hidden">
          <div
            className="absolute h-full rounded-lg bg-accent2 box-content"
            style={{
              width: `calc(${activeBonusClearedPercentage} - 8px)`,
              height: 'calc(100% - 8px)'
            }}
          />
          <p className={`${commonTextClasses} relative py-1.5 m-0`}>
            {t('auth:activeBonusClearedText', { percentage: activeBonusClearedPercentage })}
          </p>
        </div>
      }
    </>
  );
}

export default PlayerBalanceStatus;